// Debug START
var debug = false;

// check query string for debug=1
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

if (getQueryVariable("debug") == 1) {
  debug = true;
  debug && console.log("debug= " + debug);
}

// clear local storage
var clearStorage = false;
//clearStorage && localStorage.clear();
debug && console.log("debug= " + debug + " && clearStorage= " + clearStorage);

// Debug END

// used to apply zip from URL functionality 
var zipInURL = location.href.indexOf("zip=") > -1;
//Regional offer
//Region A
//var _regionA = ["AZ", "CA", "CO", "CT", "DE", "FL", "IA", "IL", "IN", "MA", "ME", "MI", "MN", "MT", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "PA", "RI", "SD", "UT", "VT", "WI"];
var _regionA = ["CA"];


class geolocation {  
  GeoVars;
  constructor() {
    // vars
    var _vars = {
      currentZipCode: "",
      destination: false, // if destination link is clicked
      originalTitleText: '',
      peFlag: false
    };

    this.GeoVars = _vars;

    // Elements
    var _elements = {
      $nav_zip_span: $(".location i span"), // Zip code in nav
      $nav_location_btn: $(".location"), // '.location' icon on nav
      $geo_overlay: $(".geo-overlay"), //geo overlay full page
      $geo_close: $(".geo-close"), // geo Overlay 'X'
      $geolocator: $(".geolocator"), // OK, USE MY LOCATION btn
      $geolocatorGo: $(".geolocator-go"), // Go geo
      $geo_input_global: $(".geo-input"), // nav & overlay geo input
      $zip_input_nav: $(".nav-zip-input"),
      $zip_input_nav_close: $(".close-nav-zip-input"), // close zip input
      $nav_right: $(".navbar-right"), //
      $nav_li_a: $(".navigation li a"), // used when link in nav is clicked
      $loading_gif: $(".tmp-honda-loader"), //loading gif,
      $secondary_nav: $(".sidebar-nav-secondary"), // mobile secondary navbar
      $nav_brand: $(".navbar-brand"), // logo brand on navbar
    };

    // pages that need zipGate
    // used for OnClick and OnLoad
    var _checkURL = [
      "/",
      "/dealer-locator",
      "/special-offers",
      "/vehicle-search",
      "/payment-estimator",
      "/vehicle-details"
    ];


    $(document).ready(function() {
      //first: Check IE/Safari security issue with LocalStorage
      LocalStorageSecurityCheck();

      /*
       * *************************************
       *  check if zip code is available in LocalStorage
       *  check if zipGate is needed based on page URL
       *  if found, update fucntions
       *
       * *************************************
       */

      // used to apply zip from URL functionality
      if (zipInURL && zipCheckLocalStorage.init()) {
        var updatedZIP = getQueryVariable("zip");
        localStorageSetItemCommon("_GeoCurrentZipCode", updatedZIP);
      }

      // Check if there is a value for zip code in LocalStorage
      if (zipCheckLocalStorage.init()) {
        // if Zip is available, call setZip to update nav '$nav_zip_span'
        setZip.updateNavZip(_vars.currentZipCode);
      } else {
        // there no zip in LocalStorage
        // find user zip code
        // Mobile and Desktop
        geolocator.getLocation();
      }

      var suggestionInterval;

      $(".search-box").on("focus", function() {
        errorBox.hide();
        var $input = $(this);
        var lastSuggestion = "";
        suggestionInterval = setInterval(function() {
          var msMap = $(".MicrosoftMap").find("ul li");

          var resultCount = msMap.length;

          if (resultCount > 0) {
            for (var i = 0; i < Math.min(3, resultCount); i++) {
              var mm = $(msMap[i]);
              var suggestion =
                mm.find(".line1").text() + ", " + mm.find(".line2").text();
              if (
                suggestion != lastSuggestion &&
                suggestion.indexOf("United States") >= 0
              ) {
                $input.data("suggestion", suggestion);
                break;
              } else {
                $input.data("suggestion", "");
              }
            }
          } else {
            $input.data("suggestion", "");
          }
        }, 60);
      });
      $(".search-box").focusout(function() {
        // debug && console.log('blur...');
        clearInterval(suggestionInterval);
      });

      $('.navigation li a, .navbar-brand a').on('click',function(e){
        e.preventDefault();
      });

      function tapHandlerReady(event) {
        window.digitalDataTrackActionTriggerActive = true;
        // wait for adobe dtm to fire before directing to inventory
        var tries = 0;
        var tapReady = setInterval(function() {
          if("function"==typeof window.TAPReadyForNewPageLoad && window.TAPReadyForNewPageLoad()) {
            clearInterval(tapReady);
            navClickHandler(event);
          }
          if(++tries === 2) {
            clearInterval(tapReady);
            navClickHandler(event);
          }
        }, 500);
      }

      // Go btn
      _elements.$geolocatorGo.on('click', function(e) {
        geolocator.geoBtnGo($(this));
      }); //click END

      // keyboard enter pressed
      _elements.$geo_input_global.keydown(function(e) {
        if (e.key == 'Enter') {
          geolocator.geoBtnGo($(this).next('.geolocator-go'));
        }
      }); //click END

      //keyboard enter pressed - zipgate overlay
      $('.geo-search-overlay, .geo-search-nav-sm').keydown(function(event) {
        if (event.key !== 'Enter') {
          return;
        }

        event.preventDefault();

        const $input = $(this);

        $input
          .siblings('.geolocator-go')
          .click();

        setTimeout(() => {
          if (!$input.val() && $input.hasClass('form-error')) {
            $input.focus();
          }
        });
      });

      // OK, use my location btn click
      _elements.$geolocator.on('click', (_e) => {
        // e.preventDefault();
        geolocator.getLocation();
      });

      let prevFocusElm;

      const closeForm = () => {
        _elements.$zip_input_nav.removeClass('active');
        _elements.$nav_right.removeClass('active');

        if (prevFocusElm) {
          prevFocusElm.focus();
          prevFocusElm = undefined;
        }
      };

      const openForm = () => {
        prevFocusElm = document.activeElement;

        _elements.$zip_input_nav.show(300).addClass('active');
        _elements.$nav_right.addClass('active');
        // highlight input val()
        _elements.$geo_input_global
          .val('')
          .focus()
          .select();
      };

      const handleLocationBtnClick = (e) => {
        // e.preventDefault();
        if (e.type === 'keydown' && e.key !== 'Enter') {
          return;
        }

        openForm();
      };

      _elements.$geo_input_global.on('keydown', (e) => {
        if (e.key === 'Escape') {
          closeForm();
        }
      });

      _elements.$nav_location_btn.on('click', handleLocationBtnClick);
      _elements.$nav_location_btn.on('keydown', handleLocationBtnClick);

      _elements.$zip_input_nav_close.on('click', function(e) {
        closeForm();
      });

      // _elements.$nav_location_btn.on('click', function(e) {
      //     //  _elements.$geo_overlay.removeClass('hidden');
      //     e.preventDefault();
      // });

      // Close Geolocation overlay onclick
      // and redirect to homepage if needed
      // or stay on the same page
      _elements.$geo_close.on("click", function(e) {
        // (SAVED) code to go back to prev page onclick:
        // onClick="history.go(-1); return false;"

        // check destination link is clicked
        if (_vars.destination || _vars.peFlag) {
          // close zipGate overlay
          _elements.$geo_overlay.toggleClass("hidden");
          _vars.peFlag = false;
          $('.geo-title h4').text(_vars.originalTitleText);
        } else {
          // call function to redirect the page
          redirect("/");
          // e.preventDefault();
        }
      });

      function navClickHandler(e) {
        // get destination path
        var desPath = e.target.getAttribute('data-destination');
        var target = e.target.getAttribute('target')
        debug && console.log("desPath: ", desPath, target);
        //if nav has data-destination
        if (desPath && target !== '_blank') {
          // destination link is clicked
          _vars.destination = true;

          // call zipGate to check URL
          zipGate.destinationURL(desPath);
        }
        // e.preventDefault();
      }

      // check nav item onclick
      // if destination needs zipGate?
      _elements.$nav_li_a.on("click", tapHandlerReady);

      _elements.$nav_right.on("click", tapHandlerReady);

      _elements.$secondary_nav.on("click", tapHandlerReady);

      _elements.$nav_brand.on("click", tapHandlerReady);
    }); //document ready END

    /*
     * *************************************
     *   zipGate START
     *   if geo fails for mobile and desktop
     *   and zip is needed for the page use
     *   geo overlay to request it
     * *************************************
     */

    var zipGate = new (function() {
      this.showOverlay = function() {
        debug && console.log("zipGate");
        // is zipGate needed

        //for tap on zipgate showing
        if(typeof window.tapGlobalModal === "function") {
          window.tapGlobalModal("geo-modalload");
        }

        if (zipGate.checkURL()) {
          debug && console.log("zipGate.checkURL() required");
          // open zipGate overlay
          _elements.$geo_overlay.removeClass("hidden");
        } else if (_vars.destination) {
          debug && console.log("zipGate.checkURL() _vars.destination");
          // if on mobile, close the menu first
          if ($("#wrapper").hasClass("toggled")) {
            // add 'padding-left' to '.wrapper'
            $("#wrapper").toggleClass("toggled");

            // add animation to hamburger menu '#menu-toggle'
            $("#menu-toggle").toggleClass("active");

            // add animation to location '#menu-toggle'
            $(".navbar-header .location").toggleClass("active");
          }
          // open zipGate overlay
          _elements.$geo_overlay.removeClass("hidden");
        } else {
          debug && console.log("zipGate.checkURL() not required");
        }
      }; // showOverlay END

      this.checkURL = function() {
        var _pathname = window.location.pathname;

        // check _pathname matches anything from _checkURL
        if ($.inArray(_pathname, _checkURL) != -1) {
          debug && console.log("_pathname is in inArray");
          debug && console.log("_pathname: " + _pathname);
          return true;
        }

        // for (var i = 0; i < _checkURL.length; i++) {

        //     debug && console.log('_pathname: ' + _pathname);
        //     if (_pathname.indexOf(_checkURL[i]) > -1) {
        //         //  if (window.location.href.indexOf(_checkURL[i]) > -1) {

        //         console.log('_checkURL:  ' + _checkURL);
        //         console.log('_checkURL[i]:  ' + _checkURL[i]);
        //         console.log(_pathname.indexOf(_checkURL[i]) > -1)
        //             // if URL matched _checkURl list
        //         return true;
        //     } else {
        //         // if URL different _checkURl

        //     }
        // } // for END
      }; // checkURL END

      this.destinationURL = function(path) {
        debug && console.log("zipGate.destinationURL: " + path);

        for (var i = 0; i < _checkURL.length; i++) {
          // if destination needs zipGate
          if (_checkURL[i] == path) {
            // destination link is clicked
            _vars.destination = true;

            // set destionation path
            _vars.destinationPath = path;

            // debug && console.log('zipGate.destinationURL: path: PASSED:' + path + ' _vars.destination: ' + _vars.destination);

            // Check if there is a value for zip code in LocalStorage
            if (zipCheckLocalStorage.init()) {
              // call function to redirect
              redirect(_vars.destinationPath);
            } else {
              debug && console.log("No zip is available destinationURL");
              // there no zip in LocalStorage
              // find user zip code
              // Mobile and Desktop
              geolocator.getLocation();
            }

            // if URL matched _checkURl
            return true;
          } else {
            debug && console.log("path: FAILEd");
            redirect(path);
            // if URL different _checkURl
          }
        } // for END
      }; // destinationURL END
    })(); // zipGate END

    /*
     * *************************************
     *  geolocator START
     * - OK, USE MY location btn click
     * - HTML5 Geolocation for Mobile
     * - getZipCodeDesktop handler for Desktop
     * *************************************
     */

    var geolocator = new (function() {
      var lat, long;

      debug && console.log("geolocator");

      // get location/position
      this.getLocation = function() {
        // if mobile user
        if (mobileTest.init()) {
          // if browser supports HTML5 'navigator.geolocation'
          // Use HTML5 'navigator.geolocation' to get lat/long
          if (navigator.geolocation) {
            // get lat/long then call 'geolocator.setPositions()'
            // Only works in 'localhost' or 'https'
            navigator.geolocation.getCurrentPosition(
              geolocator.setPositions,
              geolocator.geoBlocked,
              {
                timeout: 30000,
                enableHighAccuracy: true,
                maximumAge: 75000
              }
            );
          } else {
            // check if zip needed for current page then
            // since HTML5 geo is not supported, ask user to input location
            zipGate.showOverlay();
          }
        }
        //if desktop - call getZipCodeDesktop handler
        else {
          getZipCodeDesktop.ajaxCall();
        }
      }; // this.getLocation END

      this.geoBlocked = function() {
        debug && console.log("geo Blocked by user");

        // hide "Ok, USE MY LOCATION" btn
        _elements.$geolocator.addClass("hidden");

        // check if zip needed for current page then
        // since HTML5 geo is BLOCKED by user
        zipGate.showOverlay();
      }; //geoBlocked

      // for Mobile
      // convert lat/long from positions using 'getPinCode'
      this.setPositions = function(position) {
        lat = position.coords.latitude;
        long = position.coords.longitude;

        // update local Lat/long
        setZip.updateLocalStoragePositions(lat, long);

        // Call bing to convert to Zip
        getPinCode(lat, long, function(res, lat, lon) {
          //debug && console.log(res);

          // call updateNavZip to update nav
          // call updateLocalStorageZip to update Local Storage
          var state = state || 'CA';
          setZip.updateAll(state, res, lat, lon);
        });

        // debug && console.log(
        //   // position +
        //   "Latitude: " + lat +
        //   " Longitude: " + long
        // );
      };

      // geolocation btn click
      this.geoBtnGo = function(thisObj) {
        var $input = thisObj.parent().find('input.search-box');

        $(".geo-input-error-wrapper").removeClass("active");

        // Validate: is there val() in input
        if ($input.val()) {
          getLatLonFromQuery(
            $input.data("suggestion") || $input.val(),
            function(lat, lon, country) {
              // Error box
              var $errorBoxSpan = $(".geo-input-error-title span");

              if (country !== "United States") {
                //show the error box with the val()
                $(".geo-input-error-wrapper").addClass("active");
                $errorBoxSpan.html($(".geo-input").val());
                //add error to zipgate popup
                $(".geo-search-overlay").addClass("form-error");
                debug && console.log("USA");
                return;
              }

              // keyboard/enter
              if (
                typeof Storage !== "undefined" &&
                lat != null && lon != null
              ) {
                // set temp. lat/long
                localStorageSetItemCommon("_GeoTempLat", lat);
                localStorageSetItemCommon("_GeoTempLong", lon);
              }

              getPinCode(lat, lon, function(res, lat, lon) {
                //sometime api returns incorrect zip. So, if user searched for a zip, then show it directly
                var inputText = $input.val();
                if (inputText.length > 0 && /^\d{5}$/.test(inputText.trim())) {
                  res = inputText;
                }

                debug && console.log("Go: Zip:" + res);

                // Global zip tracking
                // if zip is chenged from zip gate overlay
                if (thisObj.parent().is("#searchBoxContainer-overlay")) {
                  _satellite.track("global_zipgate_submit");
                  if(typeof window.tapGlobalModal === "function") {
                    window.tapGlobalModal("geo-submit", res);
                  }
                  // if zip is chenged from Global Nav
                } else if (thisObj.parent().is("#searchBoxContainer-nav-sm")) {
                  _satellite.track("global_nav_zipfield_submit");
                  if(typeof window.tapGlobalNav === "function") {
                    window.tapGlobalNav("geo-submit", res);
                  }
                }

                // call updateNavZip to update nav
                // call updateLocalStorageZip to update Local Storage
                setZip.updateAll("", res, lat, lon, "true");

                // if btn on nav is clicked hide it
                if (_elements.$nav_right.hasClass("active")) {
                  _elements.$nav_right.toggleClass("active");
                } else {
                  //
                }
              });
            }
          );
        } else {
          //input is empty - no val in input
          $input.addClass("form-error");
        }

        // reset form-error
        $(".form-error").focus(function() {
          $(this).removeClass("form-error");
        });
      };
    })();
    /* geolocator END */

    /*
     * *************************************
     *  getZipCodeDesktop - Zip by IP Location handler START
     * - get zip code for desktop user from handler
     *
     * *************************************
     */

    var getZipCodeDesktop = new (function() {
      this.ajaxCall = function() {
        //TEMP - for testing
        // var res = 91335;
        // getLatLon(res, function(lat, lon, zip) {
        //     setZip.updateAll(zip, lat, lon);
        // });
        // return;
        //TEMP END

        // show gif (don't apply on inventory, VDP or DL)
        // if (location.href.indexOf("/vehicle-") == -1) {
        //   _elements.$loading_gif.fadeIn(300);
        // }

        debug && console.log("ajaxCall get-zip-by-ip-address.ashx");
        $.ajax({
          type: "POST",
          dataType: "json",
          url: "/handlers/get-zip-by-ip-address.ashx",
          data: {},

          complete: function(data, status) {
            debug && console.log("ajax data zipcode: " + JSON.stringify(data));
            data = data.responseJSON;
            var zipCode = data.zip_code
            var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
            if (status == "success" && isValidZip) {
              // debug && console.log("data: " + JSON.stringify(data));
               debug && console.log('State-------------------------------: ', data.state)

              // call updateNavZip to update nav
              // call updateLocalStorageZip to update Local Storage
              getLatLon(data.zip_code, function(lat, lon, zip) {
                setZip.updateAll(data.state, zip, lat, lon);
              });
              // hide gif
              // _elements.$loading_gif.fadeOut(300);
            } else {
              // zip code is Null/undefined OR error from API

              // check if zip needed for current page then
              // since ajax call failed, ask user to input location
              zipGate.showOverlay();
              // hide gif
              _elements.$loading_gif.fadeOut(300);

              debug && console.log("Error ajax data zipcode: " + data.zip_code);
            }
          },
          error: function(jqXHR, textStatus, errorThrown) {
            // check if zip needed for current page then
            // since ajax call failed, ask user to input location
            zipGate.showOverlay();
            // hide gif
            _elements.$loading_gif.fadeOut(300);

            debug && console.log(textStatus, errorThrown);
          }
        });
      }; //ajaxCall END
    })(); // getZipCodeDesktop END
    /* getZipCodeDesktop - IP Location handler END */

    /*
     * *************************************
     * setZip
     *
     * - update nav zip
     * - update LocalStorage with zip code
     * - update LocalStorage with lat/long
     * *************************************
     */
    var setZip = new (function() {
      // update: 1- updateNavZip() 2- updateLocalStorageZip()
      debug && console.log("setZip*updateAll");
      this.updateAll = function(state, zipCode, lat, lon, ev) {
        this.updateNavZip(zipCode);
        this.updateLocalStorageZip(zipCode);
        this.updateLocalStorageState(state);
        this.updateLocalStoragePositions(lat, lon);

        notifyOtherPage(zipCode);

        /* ev is used to make sure the zipInURL conditional only runs on URL change,
                      no when firing the geolocator-go click event */

        if (zipInURL && !ev) {
          var zipFromURL = getQueryVariable("zip");
          this.updateLocalStorageZip(zipFromURL);
          this.updateNavZip(zipFromURL);
        }

        //global function
        //$(window).trigger('geo.zipchanged', {
        //    zip: zipCode
        //});

        if (_vars.destination) {
          redirect(_vars.destinationPath);
        }
      }; //updateAll

      /*
       * *************************************
       *   update zip on:
       *   1- nav
       *   2- geo inputs
       * *************************************
       */
      this.updateNavZip = function(zipCode) {
        //  debug && console.log('updateNavZip');

        // update nav zip
        _elements.$nav_zip_span.html(zipCode);

        //update input in nav
        _elements.$geo_input_global.val(zipCode);

        // hide geo overlay
        _elements.$geo_overlay.addClass("hidden");

        // hide 'nav-zip-input'
        //temp
        _elements.$zip_input_nav.removeClass("active");

        // if btn on nav is clicked hide it
        if (_elements.$nav_right.hasClass("active")) {
          _elements.$nav_right.toggleClass("active");
        }

        //global
        //$(window).trigger('geo.zipchanged', {
        //    zip: zipCode
        //});

        // hide input error
        errorBox.hide();
      }; // updateNavZip END

      // update LocalStorage with new zip
      this.updateLocalStorageZip = function(zipCode) {
        debug && console.log("updateLocalStorageZip: " + zipCode);
        // check if browser supports localStorage
        if (typeof Storage !== "undefined") {
          if (zipCode) localStorageSetItemCommon("_GeoCurrentZipCode", zipCode);
          //global
          //$(window).trigger('geo.zipchanged', {
          //  zip: zipCode
          //});
          // debug && console.log("localStorage: " + localStorage.getItem("currentZipCode"));
        } else {
          console.log("Your browser does not support Web Storage");
        }
      }; //updateLocalStorageZip END

      
      // update LocalStorage with new state
      this.updateLocalStorageState = function(state) {
        debug && console.log("updateLocalStorageState: " + state);
        // check if browser supports localStorage
        if (typeof Storage !== "undefined") {
          if (state){
              if(_regionA.indexOf(state)  != -1){
                localStorageSetItemCommon("_GeoCurrentState", state);
                localStorageSetItemCommon("_GeoCurrentRegion", "A");
              } else {
                localStorageSetItemCommon("_GeoCurrentState", state);
                localStorageSetItemCommon("_GeoCurrentRegion", "B");
              }
          
          }

        } else {
          console.log("Your browser does not support Web Storage");
        }
      }; //updateLocalStorageState END

      // update LocalStorage with new Lat/long
      this.updateLocalStoragePositions = function(lat, long) {
        debug && console.log("updateLocalStoragePositions");
        // check if browser supports localStorage
        if (typeof Storage !== "undefined" && lat != null && long != null) {
          localStorageSetItemCommon("_GeoCurrentLat", lat);
          localStorageSetItemCommon("_GeocurrentLong", long);
        } else {
          // no support
        }
      }; //updateLocalStoragePositions END
    })(); /* setZip  END */

    /*
     * *************************************
     * mobileTest
     *
     * *************************************
     */
    var mobileTest = new (function() {
      this.init = function() {
        var isMobile = false;
        //Detect mobile
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
          )
        )
          isMobile = true;

        if (isMobile) {
          debug && console.log("This is mobile");
          return true;
        } else {
          debug && console.log("This is desktop");
          return false;
        }
      }; //init END
    })(); /* mobileTest END */

    /*
     * *************************************
     * zipCheckLocalStorage
     * check if zip code is in LocalStorage
     * *************************************
     */
    var zipCheckLocalStorage = new (function() {
      this.init = function() {
        // look for "currentZipCode" in localStorage
        _vars.currentZipCode = localStorageGetItemCommon("_GeoCurrentZipCode");
        debug &&
          console.log("zipCheckLocalStorage zip: " + _vars.currentZipCode);
        if (_vars.currentZipCode) {
          // if (_vars.currentZipCode !== null) {
          return true;
        } else {
          debug &&
            console.log(
              "_vars.currentZipCode: Zip is NUll/Not there " +
                _vars.currentZipCode
            );
          return false;
        }
      }; //init END
    })(); /* zipCheckLocalStorage END */

    // function to redirect
    function redirect(path) {
      debug && console.log(" Redirecting to: " + path);
      window.location.href = path;
    }
  } //constructor() END

  setDestinationForPE(){
    this.GeoVars.peFlag = true;
    this.GeoVars.originalTitleText = $('.geo-title h4').text();
    $('.geo-title h4').text('Please Enter Your ZIP Code');
  }

  static displayZip(zip) {
    _elements.$nav_zip_span.html(zip);
  }

  static updateZip(zip) {
    if (typeof Storage !== "undefined") {
      if (zip) localStorageSetItemCommon("_GeoCurrentZipCode", zip);
    } else {
      console.log("Your browser does not support Web Storage");
    }
  }
} // class geolocation END

/*
 * *************************************
 *  BING MAP START
 *  BING map code will be here
 * *************************************
 */

function CallRestService(request, callback) {
  $.ajax({
    url: request,
    dataType: "jsonp",
    jsonp: "jsonp",
    success: function(r) {
      callback(r);
      onApiSuccess();
    },
    error: function(e) {
      debug && console.log(e.statusText);
      onApiError();
    }
  });
} //CallRestService END

function loadMapScenario() {
  Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", {
    callback: onLoad,
    errorCallback: onError,
    credentials: bingMapsAPIKey
  });

  function onLoad() {
    var options = {
      maxResults: 5,
      addressSuggestions: false,
      autoDetectLocation: true
      // userLocation: new Microsoft.Maps.Location(40.679553, -96.807385)
    };
    var manager = new Microsoft.Maps.AutosuggestManager(options);
    var manager2 = new Microsoft.Maps.AutosuggestManager(options);
    manager.attachAutosuggest(
      ".geo-search-nav-sm",
      "#searchBoxContainer-nav-sm",
      onSuggestionSelected
    );
    manager2.attachAutosuggest(
      ".geo-search-overlay",
      "#searchBoxContainer-overlay",
      onSuggestionSelected2
    );
  }

  function onError(message) {
    document.getElementById("printoutPanel").innerHTML = message;
  }
  //selectedSuggestion END

  setTimeout(function() {
    if (typeof initPage == "function" || typeof initPage == typeof Function) {
      debug && console.log("Geo -  initPage()");
      initPage();
    } else {
      debug && console.log("Geo - initPage() Error");
    }
  }, 500);
}

//calls when item is selected from suggestion
var mapData;

function onSuggestionSelected(selectedData) {
  onSuggestionSelectedCommon(selectedData, 1);
}

function onSuggestionSelected2(selectedData) {
  onSuggestionSelectedCommon(selectedData, 2);
}

function onSuggestionSelectedCommon(selectedData, type) {
  //item was selected by user. so remove the first auto-complete suggestion
  $(".search-box")
    .eq(type - 1)
    .data("suggestion", "");

  var data = getSuggestionData(selectedData);
  mapData = data;

  if ((mapData.address || "").indexOf("United States") < 0) {
    errorBox.show();
    return;
  }

  // map.setView({ bounds: selectedData.bestView });

  // document.getElementsByClassName('printoutPanel')[type-1].innerHTML =
  debug &&
    console.log(
      // 'Suggestion: ' + data.address +
      "Lat: " + data.lat + "Lon: " + data.lon
    );

  // check if browser supports localStorage
  if (typeof Storage !== "undefined" && data.lat != null && data.lon != null) {
    // set temp. lat/long
    localStorageSetItemCommon("_GeoTempLat", data.lat);
    localStorageSetItemCommon("_GeoTempLong", data.lon);
  }

  getPinCode(data.lat, data.lon, function(res, lat, lon) {
    debug && console.log("zip: " + res);
    // document.getElementsByClassName('printoutPanel')[type - 1].innerHTML += "<br>PostalCode:" + res;
  });

  //autosuggest selected auto search
  if (type === 1) {
    setTimeout(function() {
      $(".geo-search-nav-sm")
        .siblings(".geolocator-go")
        .trigger("click");
    }, 1000);
  } else if (type === 2) {
    setTimeout(function() {
      $(".geo-search-overlay")
        .siblings(".geolocator-go")
        .trigger("click");
    }, 1000);
  }
}

//returns the data in proper format
function getSuggestionData(selectedData) {
  if (selectedData == null) {
    return;
  }
  debug && console.log(selectedData);

  var res = {
    address: selectedData.formattedSuggestion
  };

  if (selectedData.location) {
    res.lat = selectedData.location.latitude;
    res.lon = selectedData.location.longitude;
  }
  return res;
}

function getPinCode(lat, lon, callback) {
  CallRestService(
    "https://dev.virtualearth.net/REST/v1/Locations/" +
      lat +
      "," +
      lon +
      "?key=" +
      bingMapsAPIKey_AutoComplete,

    function(res) {
      //debug && console.log('getPinCode:', res);
      if (res.resourceSets["0"].estimatedTotal > 0) {
        callback(res.resourceSets[0].resources[0].address.postalCode, lat, lon);
        onApiSuccess();
      } else {
        debug && console.log("getPinCode: Not match found!");
        //callback(null, lat, lon);
        onApiError();
      }
    }
  );
}

function getLatLon(zip, callback) {
  CallRestService(
    "https://dev.virtualearth.net/REST/v1/Locations?postalCode=" +
      zip +
      "&key=" +
      bingMapsAPIKey_AutoComplete,

    function(res) {
      debug &&
        console.log(
          "962 +++++++++++++++++++++++++++++++++++ getLatLon res:",
          res
        );
      if (res.resourceSets["0"].estimatedTotal > 0) {
        callback(
          res.resourceSets[0].resources[0].geocodePoints[0].coordinates[0],
          res.resourceSets[0].resources[0].geocodePoints[0].coordinates[1],
          zip
        );
        onApiSuccess();
      } else {
        debug && console.log("getLatLon: Not match found!");
        //callback();
        onApiError();
      }
    }
  );
}

function getLatLonFromQuery(query, callback) {
  debug && console.log("query:", query);
  CallRestService(
    "https://dev.virtualearth.net/REST/v1/Locations?q=" +
      encodeURIComponent(query) +
      "&key=" +
      "Ap0AObt84NcDaUThCeWOj52ZqUHv6k4TJhjLibR-DghC-semgoj-0uPbIi8r0E4j",

    function(res) {
      //debug && console.log('getLatLonFromQuery:', res);

      if (res.resourceSets["0"].estimatedTotal > 0) {
        //if any resource has entityType:postalcode1 then use that else use first
        var resources = res.resourceSets["0"].resources;
        var resource;
        var currentState = resources[0].address.adminDistrict;
        debug &&
          console.log("995********************* resourcesS:", currentState);
        if (currentState){
          if(_regionA.indexOf(currentState) != -1){
            localStorageSetItemCommon("_GeoCurrentState", currentState);
            localStorageSetItemCommon("_GeoCurrentRegion", "A");
          } else {
            localStorageSetItemCommon("_GeoCurrentState", currentState);
            localStorageSetItemCommon("_GeoCurrentRegion", "B");
          }
        }
          

        for (var i = 0; i < resources.length; i++) {
          if (resources[i].entityType.indexOf("Postcode") >= 0) {
            resource = resources[i];
            break;
          }
        }

        if (resource == null) {
          resource = resources["0"];
        }

        callback(
          resource.geocodePoints["0"].coordinates["0"],
          resource.geocodePoints["0"].coordinates["1"],
          resource.address.countryRegion
        );
        onApiSuccess();
      } else {
        debug && console.log("getLatLonFromQuery: Not match found!");
        //callback();
        onApiError();
      }
    }
  );
}

function onApiError() {
  debug && console.log("No data from API or some API error!");
  errorBox.show();
}

function onApiSuccess() {
  //errorBox.hide();
}
//loadMapScenario END

/*  BING MAP  END      */

/*
 * *************************************
 *  errorBox START
 *
 * *************************************
 */

// nav error box
// zipgate error (form-error)
var errorBox = new (function() {
  debug && console.log("errorBox");
  var errorRes = $(".geo-input-error-title span");
  errorRes.html("");

  //zipgate overlay reset
  $(".geo-search-overlay").removeClass("form-error");

  this.show = function() {
    //nav error box show
    $(".geo-input-error-wrapper").addClass("active");
    errorRes.html($(".geo-input").val());

    //zipgate overlay add error
    $(".geo-search-overlay").addClass("form-error");
  };
  this.hide = function() {
    //nav error box hide
    $(".geo-input-error-wrapper").removeClass("active");
    //zipgate overlay reset
    $(".geo-search-overlay").removeClass("form-error");
  };
})();
// errorBox END

//function TriggerGeoZipChanged() {
//    var evt = $.Event('geo.zipchanged');
//    $(window).trigger(evt);
//}
$(window).on("app.zipchanged", function(e, data) {
  //debug && console.log('--- dl zip changed ---');
  updateIncomingZip(data.zip);
  debug && console.log("1074________________________ data: ", data);
  if (typeof Storage !== "undefined") {
    if (data.zip) localStorageSetItemCommon("_GeoCurrentZipCode", data.zip);
    debug && console.log("--- dl zip changed --- New Zip" + data.zip);
  } else {
    console.log("Your browser does not support Web Storage");
  }
});

//update nav zip based on the new incoming zip
function updateIncomingZip(dataZip) {
  $(".location i span").html(dataZip);
  debug && console.log("--- updateIncomingZip --- New Zip: ", dataZip);
}
//update other page of the new zip
function notifyOtherPage(zipcode) {
  setTimeout(function() {
    debug && console.log("Geo - notifyOtherPage");
    if (typeof initPage == "function" || typeof initPage === typeof Function) {
      initPage();
      if (window.platform) {
        window.platform.pe.updateZipCode(zipcode);  
      }      
    } else {
      debug && console.log("Geo - initPage() Error");
    }
  }, 500);
}

// use cookies when LocalStorage is not permitted
var cookieStorage = new (function() {
  this.getCookie = function(key) {
    var _currentCookie = Cookies.get(key);
    if (_currentCookie) {
      debug && console.log("cookie is defined if: " + _currentCookie);
      return Cookies.get(key);
    } else {
      debug && console.log("cookie is undefined else");
      return false;
    }
  };
  this.setCookie = function(key, value) {
    debug && console.log("setCookie " + key + " " + value);
    Cookies.set(key, value);
  };
})();

// check if LocalStorageSecurityCheck
// IE, in some version 11 might not allow LocalStorage
var _SecurityCheck = false;

function LocalStorageSecurityCheck() {
  var lStorage;
  // check if browser supports localStorage - For IE11
  try {
    lStorage = localStorage;
    try {
      // Test webstorage accessibility - Needed for Safari private browsing.
      localStorage.setItem("storage_test", 1);
      localStorage.removeItem("storage_test");
      _SecurityCheck = false;
      // Safari Private browsing
    } catch (e) {
      _SecurityCheck = true;
    }

    // No support for localStorage
  } catch (e) {
    _SecurityCheck = true;
  }
}

//global function to setitem in localstorage or cookie
function localStorageSetItemCommon(key, value) {
  if (_SecurityCheck) {
    debug && console.log("Using SET Cookies");
    // use fallback cookies
    cookieStorage.setCookie(key, value);
  } else {
    debug && console.log("Using SET Storage");
    //use localStorage
    localStorage.setItem(key, value);
  }
}

//global function to getitem in localstorage or cookie
function localStorageGetItemCommon(key) {
  if (key != null) {
    if (_SecurityCheck) {
      debug && console.log("Using GET Cookies");
      // use fallback cookies
      return cookieStorage.getCookie(key);
    } else {
      debug && console.log("Using GET STORAGE");
      //use localStorage
      return localStorage.getItem(key);
    }
  } else {
    debug && console.log("localStorageGetItemCommon Key Null");
  }
}
